@import '../../../../cl_base/scss/theme-bootstrap';

#mpp-product-coresite {
  @include breakpoint ($cr19-small) {
    padding: 0;
  }
  .mpp-product-coresite-header {
    padding: 0 72px 16px;
    line-height: 1.3;
    font-size: 58px;
    font-family: $base-bolder-font-family;
    color: $color-black;
    p {
      margin: 0;
    }
    @include breakpoint ($cr19-small) {
      font-size: 26px;
      padding: 0 25px 16px;
    }
  }
  .mpp-product-coresite-subheader {
    padding: 0 72px;
    line-height: 1.3;
    font-size: 19px;
    font-family: $base-font-family-roman;
    letter-spacing: 0.02em;
    color: $color-black;
    @include breakpoint ($cr19-small) {
      font-size: 15px;
      padding: 0 25px 16px;
      p {
        margin: 0;
      }
    }
  }
}

.vertical-carousel-navigation-v1 {
  .module {
    border: none;
  }
}
